/** 
    @see https://www.npmjs.com/package/@jitsu/sdk-js
*/
import { useCallback, useEffect } from 'react';
import { setIdentify } from './identify';
import {
  setDineMode,
  setProductAddedToCart,
  setProductRemoved,
  setUpsellAddedToCart,
  setOrderUpdated,
  setOrderCompleted,
  setPaymentStarted,
  setOrderViewed,
  setPaymentCompleted,
  setProductViewed,
  setUpsellDismissed,
  setPage,
  setBeginCheckoutTrack,
  setUpsizeItemSelected,
  setCartItemEdit,
  setItemSelectTrack,
  setEmbeddedErrorTrack,
} from './track';
import { setResetAnalytics } from './reset';
import { JITSU_LOG_TAG } from './utilities';

export const useAnalytics = ({ isFirstCall = false } = {}) => {
  useEffect(() => {
    if (isFirstCall) {
      if (process.env.POI_APP_JITSU_API_KEY) {
        logger.debug(`${JITSU_LOG_TAG} enabled`);
      } else {
        logger.error(`${JITSU_LOG_TAG} disabled. Jitsu API key is missing`);
      }
    }
  }, [isFirstCall]);

  const setAnalyticsIdentify = useCallback(async (session_id, settings) => {
    await setIdentify(session_id, settings);
  }, []);

  const resetAnalytics = useCallback(() => {
    setResetAnalytics();
  }, []);

  return {
    setAnalyticsIdentify,
    resetAnalytics,
    dineModeTrack: setDineMode,
    productAddedToCartTrack: setProductAddedToCart,
    beginCheckoutTrack: setBeginCheckoutTrack,
    productRemovedTrack: setProductRemoved,
    upsizeItemSelectedTrack: setUpsizeItemSelected,
    upsellAddedToCartTrack: setUpsellAddedToCart,
    cartItemEditTrack: setCartItemEdit,
    orderCompletedTrack: setOrderCompleted,
    paymentStartedTrack: setPaymentStarted,
    paymentCompletedTrack: setPaymentCompleted,
    itemSelectTrack: setItemSelectTrack,
    embeddedErrorTrack: setEmbeddedErrorTrack,
    productViewedTrack: setProductViewed,
    orderViewedTrack: setOrderViewed,
    orderUpdatedTrack: setOrderUpdated,
    upsellDismissedTrack: setUpsellDismissed,
    setPageTrack: setPage,
  };
};
