import React from 'react';

const ArrowButton = ({
  className = 'capitalize',
  onClick = () => {},
  disabled = false,
  isArrowInverse = false,
  'data-test': dataTest = 'button-primary',
}) => {
  return (
    <button
      disabled={disabled}
      className={`arrow-button ${className}`}
      onClick={onClick}
      data-test={dataTest}
    >
      {isArrowInverse ? '<' : '>'}
    </button>
  );
};

export { ArrowButton };
