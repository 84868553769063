import React from 'react';

import { PrimaryHome } from '../../components/stateless/primary-home';
import { SecondaryHome } from '../../components/stateless/secondary-home';

import {
  Alert,
  Loading,
  useRestaurantHomeView,
  AdminButton,
} from '@fingermarkglobal/cringer.components';

const CustomHomeView = () => {
  const {
    t,
    state,
    loading,
    onAdminActivate,
    showSecondaryHome,
    accessibilityEnabled,
    isCurrentLang,
    languages,
    primaryAction,
    secondaryAction,
    onChangeLanguage,
    showSecondaryAction,
    card,
    cash,
    icons,
    isOnline,
    healthMsg,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
  } = useRestaurantHomeView();
  const params = {
    accessibilityEnabled,
    languages,
    onChangeLanguage,
    isCurrentLang,
    primaryAction,
    secondaryAction: showSecondaryAction && secondaryAction,
    t,
    card,
    cash,
    icons,
    healthMsg,
    setShowTermsAndConditionsModal,
    showTermsAndConditionsButton,
  };

  return (
    <div className="home">
      <AdminButton onActivate={onAdminActivate} />
      {loading && <Loading isFirstLoad={true} />}
      {state?.matches('failure') && <Alert type="danger" message={state.context.message} />}
      {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
      {state?.matches('success') &&
        (!isOnline ? (
          <Alert
            type="warning"
            message={t('homeConnectivityIssues')}
            className="text-white mt-home-connectivity"
          />
        ) : showSecondaryHome ? (
          <SecondaryHome {...params} />
        ) : (
          <PrimaryHome {...params} />
        ))}
    </div>
  );
};

export { CustomHomeView };
