import { useCallback, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { cartState } from '../../atoms';
import { evaluate } from '../../utilities/evaluate';
import { format } from '../../utilities/format';
import { reformat } from '../../utilities/reformat';
import { createUpdater } from '../../utilities/update';
import { offersAndRewardsState } from '@fingermarkglobal/atoms/dist/atoms.cjs.production.min';

const useStage = () => {
  const cart = useRecoilValue(cartState);
  const [staged, setStaged] = useState();
  const stagedRef = useRef();
  const offersAndRewards = useRecoilValue(offersAndRewardsState);

  const setStagedHandler = useCallback(
    valueOrUpdater => {
      const contextValue = stagedRef.current;

      if (typeof valueOrUpdater === 'function') {
        stagedRef.current = evaluate({ product: valueOrUpdater(contextValue) });
      } else {
        let product;
        const isOffer = !!offersAndRewards?.offers?.find(
          offer => offer.promoId === valueOrUpdater.providerData.PromoId,
        );
        if (isOffer) {
          product = {
            ...valueOrUpdater,
            isOffer: true,
          };
        } else {
          product = {
            ...valueOrUpdater,
            isOffer: false,
          };
        }
        stagedRef.current = evaluate({ product });
      }

      logger.debug('cart - setStagedHandler - staged:', {
        id: stagedRef.current?.productId,
        name: stagedRef.current?.name?.text,
      });

      setStaged(stagedRef.current);
    },
    [offersAndRewards],
  );

  /**
   * Stage a product by using one of the parameters in the following order:
   * - If uid is present, it will find this uid in the cart to stage;
   * - If staged is present, it will reformat the item to stage;
   * - Otherwise, it will format this hydrated product to stage.
   */
  const stage = useCallback(
    ({ product, staged, uid } = {}) => {
      const update = createUpdater({ set: setStagedHandler });

      if (uid) {
        const cartItem = cart.find(item => item.uid === uid);
        setStagedHandler(reformat({ product: cartItem, update, isRootProduct: true }));
      } else if (staged) {
        setStagedHandler(reformat({ product: staged, update, isRootProduct: true }));
      } else {
        setStagedHandler(format({ product, update, isRootProduct: true }));
      }
    },
    [cart, setStagedHandler],
  );

  return { stage, staged };
};

export { useStage };
