import React from 'react';

import {
  CategoriesHeader,
  SecondaryButton,
  usePrice,
  LoyaltyBanner,
} from '@fingermarkglobal/cringer.components';
import { ArrowButton } from '../basket-action/';

const Header = ({
  title = null,
  total = null,
  className = '',
  showCategoriesHeader = true,
  previousAction = null,
  allowPreviousAction = true,
  isLogoEnabled = true,
  isTitleEnabled = true,
  nextAction = null,
  allowNextAction = true,
  nextActionClass = '',
  showSubtotal = true,
  couponText = 'Enter offer',
  isCouponEnabled = false,
  showCouponButton = false,
  couponAction = () => {},
  computedAction = () => {},
  showLoyaltyBanner = false,
} = {}) => {
  const { formatPrice } = usePrice();
  return (
    <div data-test="header" className={`header ${className}`}>
      {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
      {showCategoriesHeader && <CategoriesHeader isItemRounded={false} className="text-white" />}
      {showLoyaltyBanner && <LoyaltyBanner computedAction={computedAction} />}
      {!showCategoriesHeader && (
        <div className="header__logo-wrapper">
          {isLogoEnabled && <div className="header__logo-wrapper__logo" />}
        </div>
      )}
      <div className="header__content-wrapper">
        <div className="header__content-wrapper__previous">
          {previousAction && (
            <ArrowButton
              className="arrow-button__left"
              data-test="arrow-back-button"
              disabled={!allowPreviousAction}
              isNextAction={false}
              isArrowInverse={true}
              onClick={previousAction}
            />
          )}
        </div>
        <div className="header__content-wrapper__title-wrapper">
          {isTitleEnabled && (
            <h1 className="header__content-wrapper__title-wrapper__title">{title}</h1>
          )}
        </div>
        <div className="header__content-wrapper__subtotal-next-coupon-wrapper">
          {showSubtotal && (
            <p
              className="header__content-wrapper__subtotal-next-coupon-wrapper__subtotal"
              data-test="header-cart-subtotal"
            >
              {formatPrice({ price: total })}
            </p>
          )}
          {nextAction && (
            <div className="header__content-wrapper__next">
              <ArrowButton
                data-test="arrow-next-button"
                disabled={!allowNextAction}
                className={`arrow-button__right ${nextActionClass}`}
                onClick={nextAction}
              />
            </div>
          )}
          {isCouponEnabled && showCouponButton && (
            // TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916
            <SecondaryButton
              className="py-0.5 font-bold text-4xl ml-8 header-offer-button"
              onClick={couponAction}
              data-test="enter-offer-button"
            >
              {couponText}
            </SecondaryButton>
          )}
        </div>
      </div>
    </div>
  );
};

export { Header };
