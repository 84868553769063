import React from 'react';

import {
  BannerWarning,
  PrimaryButton,
  LocaleSelection,
  HomeCustomisation,
} from '@fingermarkglobal/cringer.components';

import WashHandsImage from '../../../assets/images/wash-hands.png';
import DefaultBackgroundImage from '../../../assets/images/HardeesBG.jpg';

const PrimaryHome = ({
  t = () => {},
  healthMsg = true,
  languages = [],
  primaryAction,
  isCurrentLang,
  secondaryAction,
  onChangeLanguage = () => {},
  accessibilityEnabled,
  showTermsAndConditionsButton = false,
  setShowTermsAndConditionsModal = () => {},
}) => {
  const contentClass =
    isCurrentLang('uae') && 'primary-home__buttons-container__primary-button--uae';

  const marginClass = showTermsAndConditionsButton && healthMsg ? 'extra-margin' : 'margin';

  return (
    <HomeCustomisation.Provider
      fallback={{
        background: {
          image: DefaultBackgroundImage,
        },
      }}
    >
      <HomeCustomisation.Background />

      <div data-test="primary-home" className="z-10 primary-home">
        <div
          data-test="home-upper"
          className={`primary-home__buttons-container primary-home__buttons-container--banners-${marginClass}`}
        >
          <div className="flex items-center justify-center w-full ml-0">
            {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
            {!!primaryAction && (
              <PrimaryButton
                className={`w-2/5 px-0 mb-12 mr-4 text-button md:px-6 lg:px-6 lg:py-6 lg:text-button-large ${contentClass}`}
                onClick={primaryAction}
              >
                {t('homePrimaryAction')}
              </PrimaryButton>
            )}

            {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
            {!!secondaryAction && (
              <PrimaryButton
                data-test="button-secondary"
                className={`w-2/5 px-0 mb-12 ml-4 text-button md:px-6 lg:px-6 lg:py-6 lg:text-button-large ${contentClass}`}
                onClick={secondaryAction}
              >
                {t('homeSecondaryAction')}
              </PrimaryButton>
            )}
          </div>
        </div>

        <div className="primary-home__loadable-image-container" data-test="home-lower">
          {showTermsAndConditionsButton && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-terms"
              className="flex-col w-11/12 mb-24 sm:flex-row sm:w-10/12 md:w-4/5 md:mb-48 md:flex"
              title={t('homeTermsAndConditionsBannerWarningTitle')}
              description={t('homeTermsAndConditionsBannerWarningDescription')}
              additionalDescription={t('homeTermsAndConditionsBannerWarningAdditionalDescription')}
              additionalDescriptionAction={() => setShowTermsAndConditionsModal(true)}
            />
          )}

          {healthMsg && (
            <BannerWarning
              t={t}
              width="109"
              data-test="banner-health"
              image={WashHandsImage}
              className={`flex-col w-11/12 mb-${
                showTermsAndConditionsButton ? '2' : '24'
              } sm:flex-row sm:w-10/12 md:w-4/5 md:mb-${
                showTermsAndConditionsButton ? '2' : '48'
              } md:flex`}
            />
          )}
        </div>

        <div className="primary-home__accessibility-languages-container" data-test="home-footer">
          <p
            className={`primary-home__accessibility-languages-container__accessibility 
          primary-home__accessibility-languages-container__accessibility--${
            accessibilityEnabled ? 'on' : 'off'
          }`}
          >
            {t('languageSelectionTitle')}
          </p>

          {/* TODO: CCC - Migrate this style to cringer.styles = https://fingermarkglobal.atlassian.net/browse/S2-4916 */}
          <LocaleSelection languages={languages} onChangeLanguage={onChangeLanguage} />
        </div>
      </div>
    </HomeCustomisation.Provider>
  );
};

export { PrimaryHome };
